import Vue from 'vue'
import { decode } from '@/lib/util'
import '../v-video/v-video.vue'
import '../picture/picture.vue'
import '../product-card/product-card.vue'

Vue.component('hero', {
  props: {
    title1: {
      type: String,
      default: '',
    },
    title2: {
      type: String,
      default: '',
    },
    bottomTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: null,
    },
    mobileAspectRatio: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: null,
    },
    imageDesktop: {
      type: String,
      default: null,
    },
    videoUrlMobile: {
      type: String,
      default: null,
    },
    videoUrlDesktop: {
      type: String,
      default: null,
    },
    videoSubtitles: {
      type: String,
      default: null,
    },
    autoPlayVideo: {
      type: Boolean,
      default: false,
    },
    initialProduct: {
      type: [String, Object],
      default: null,
    },
    lightText: {
      type: Boolean,
      default: false,
    },
    lightNavText: {
      type: Boolean,
      default: false,
    },
    showScrollDownButton: {
      type: Boolean,
      default: false,
    },
    showVideoVolumeButton: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    isSlide: {
      type: Boolean,
      default: false,
    },
    decode: {
      type: Boolean,
      default: false,
    },
    autoDelayDesktop: {
      type: [String, Number],
      default: '',
    },
    autoDelayMobile: {
      type: [String, Number],
      default: '',
    }
  },
  data() {
    let product
    if (!!this.decode && this.initialProduct) {
      product = JSON.parse(decode(this.initialProduct))
    } else {
      product = (this.initialProduct)
    }

    return {
      product,
      showProduct: false,
      backgroundCtaCount: 0,
    }
  },
  computed: {
    videoUrls() {
      const videoUrls = []
      if (this.videoUrlMobile) videoUrls.push(this.videoUrlMobile)
      if (this.videoUrlDesktop) videoUrls.push(this.videoUrlDesktop)

      return videoUrls
    },
    getPromoBarHeight() {
      const promobar = document.querySelector('.js-promobar')
      return promobar ? promobar.offsetHeight : 0
    },
  },
  watch: {
    showProduct(val) {
      const body = document.querySelector('body')

      if (val) {
        setTimeout(() => {
          body.addEventListener('click', this.onClickOutside)
        })
      } else {
        body.removeEventListener('click', this.onClickOutside)
      }
    },
  },
  mounted() {
    if (!this.isSlide && this.lightNavText) {
      document.body.classList.add('nav-white')
    }
  },
  beforeUnmount() {
    window.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    onClickOutside(e) {
      const productCardEl = this.$refs.productCard
      if (productCardEl && !productCardEl.contains(e.target)) {
        e.preventDefault()
        this.showProduct = false
      }
    },
    scrollDown() {
      window.scrollTo({
        top: this.$refs.hero.clientHeight,
        behavior: 'smooth',
      })
    },
    getBackgroundCtaCount() {
      if (!this.$refs.heroBackground) return

      this.backgroundCtaCount = this.$refs.heroBackground.querySelectorAll(
        '.video__ctas button'
      ).length
    },
  },
})